import React from "react";
import { SERVICES_LINK } from "../utils/constants";
import { AICODING_ALLBUTTON_DATA_LIST, PROJECT_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import PrimaryButton from "./common/PrimaryButton";
import ProjectCard from "./common/ProjectCard";
import SubHeading from "./common/SubHeading";

const AiCoding = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: SERVICES_LINK,
    setHandleActiveTag,
  });
  return (
    <div id="services" className="bg-white">
      <div className="max-w-[1488px] px-4 sm:px-7 md:px-9 lg:px-20  mx-auto py-12 md:py-16 lg:py-20 xl:py-32">
        <SubHeading data-aos="fade-up">App, web, AI, & coding</SubHeading>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="max-w-[950px] mx-auto flex flex-wrap justify-center gap-x-3 gap-y-4 md:gap-y-[21px] pt-8 md:pt-12 lg:pt-[57px]"
        >
          {AICODING_ALLBUTTON_DATA_LIST.map((obj, i) => (
            <span
              key={i}
              className={`${i % 12 < 6
                  ? i % 3 === 0
                    ? "bg-antique-brass"
                    : i % 3 === 1
                      ? "bg-pacific-blue"
                      : "bg-rosy-pink"
                  : i % 3 === 0
                    ? "bg-rosy-pink"
                    : i % 3 === 1
                      ? "bg-antique-brass"
                      : "bg-pacific-blue"
                } text-white text-sm md:text-base py-3 md:py-[15px] px-5 md:px-6 lg:px-7 rounded-[40px] md:rounded-[50px] lg:rounded-[64px] font-medium duration-500 transition-all ease-out`}
            >
              {obj}
            </span>
          ))}
        </div>
        <div className="flex flex-wrap justify-center -mx-4 pt-4 lg:pt-8 pb-8 md:pb-12 lg:pb-16 xl:py-[67px]">
          {PROJECT_LIST.map((obj, i) => (
            <div key={i} className="w-full sm:w-1/2 px-2 lg:px-4 md:w-1/3">
              <ProjectCard aos={true} data={obj} />
            </div>
          ))}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="flex justify-center"
        >
          <PrimaryButton
            url="https://www.dropbox.com/s/sla3qxuvb7h8690/Floatingworld%20Case%20Studies.pdf?dl=1"
            className={"xl:px-[46px]"}
            download="Floatingworld-Case-Studies.pdf"
          >
            Download Case Studies
          </PrimaryButton>

        </div>
      </div>
    </div>
  );
};

export default AiCoding;
