"use client";
import React, { createContext, useContext, useState } from "react";

// Create the context with initial values
export const GlobalInfo = createContext({
  handleActiveTag: "",
  setHandleActiveTag: () => {},
});

/**
 * Hook to access the global information context.
 * @returns Context values and functions to manage global information.
 */
export function useGlobalInfoProvider() {
  return useContext(GlobalInfo);
}

/**
 * Context provider component for managing global information.
 * @param children Components to be rendered within the provider.
 * @returns Rendered provider component.
 */
export function GlobalInformation({ children }) {
  const [handleActiveTag, setHandleActiveTag] = useState("");

  // Context value containing the state and the function to update it
  const value = {
    handleActiveTag,
    setHandleActiveTag,
  };

  // Provide the context value to child components
  return <GlobalInfo.Provider value={value}>{children}</GlobalInfo.Provider>;
}
