import React from "react";
import { Link } from "react-router-dom";
import { PARTNERSHIP_LINK } from "../utils/constants";
import { PARTNER_LOGOS_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Icons from "./common/Icons";

const PartnersSlide = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: PARTNERSHIP_LINK,
    setHandleActiveTag,
  });

  return (
    <div id="partnerslide" className="bg-light-black -mt-0.5">
      <div className="overflow-x-clip mx-auto coursal group flex">
        <div className="flex max-2xl:gap-5 items-center animate-slide-partner slide_container">
          {PARTNER_LOGOS_LIST.map((obj, i) => (
            <div key={i} className="flex items-center">
              <div className="relative z-10">
                <Link to="#">
                  <img
                    loading="lazy"
                    src={obj}
                    alt="company logo"
                    className="lg:min-w-[249px] duration-300 rounded-2xl mix-blend-color-dodge min-w-[170px] h-[90px] lg:h-[95px] object-cover"
                  />
                </Link>
              </div>
              <span>
                <Icons icon="pointsSlider" />
              </span>
            </div>
          ))}
        </div>
        <div className="flex max-2xl:gap-5 items-center animate-slide-partner slide_container">
          {PARTNER_LOGOS_LIST.map((obj, i) => (
            <div key={i} to="#" className="flex items-center">
              <div className="relative z-10">
                <Link to="#">
                  <img
                    loading="lazy"
                    src={obj}
                    alt="company logo"
                    className="lg:min-w-[249px] duration-300 rounded-2xl mix-blend-color-dodge min-w-[170px] h-[90px] lg:h-[95px] object-cover"
                  />
                </Link>
              </div>
              <span>
                <Icons icon="pointsSlider" />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnersSlide;
