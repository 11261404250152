import React from "react";

const Paragraph = ({
  className,
  left,
  right,
  maxWidth,
  small,
  white,
  children,
  big,
  medium,
  bold,
  ...props
}) => {
  const {...rest } = props;
  return (
    <p
      {...rest}
      style={{ maxWidth: `${maxWidth && maxWidth}px` }}
      className={`${className && className} ${
        left
          ? "ml-0 mr-auto text-left"
          : right
          ? "mr-0 text-right"
          : "mx-auto text-center"
      } ${
        small
          ? "text-base md:text-lg lg:text-custum-base"
          : big
          ? "text-xl md:text-custum-base lg:text-custum-md"
          : "text-sm sm:text-base lg:text-lg"
      } ${white ? "text-white" : "text-black"} ${
        medium ? "font-medium" : bold ? "font-bold" : "font-normal"
      } !leading-127`}
    >
      {children}
    </p>
  );
};

export default Paragraph;
