import React from "react";
import pageLogo from "../../assets/images/webp/page-logo.webp";
import { NAV_LINKS_LIST } from "../../utils/helper";
import { useGlobalInfoProvider } from "./GlobalProvider";
import Icons from "./Icons";
const NavBar = () => {
  
  const { handleActiveTag } = useGlobalInfoProvider();
  // Function to determine active tab styling
  const activeTabsHandler = (url) => {
    return handleActiveTag === url
      ? "bg-blue-600 !rounded-[60px] !text-white"
      : "hover:text-blue-600 hover:bg-white";
  };
  return (
    <div
      data-aos="zoom-in"
      data-aos-delay="900"
      data-aos-anchor-placement="bottom-bottom"
      className="w-full fixed bottom-6 z-[100]"
    >
      <div className="mx-auto max-w-[644px] hidden md:block w-full border-[2px] border-solid border-black rounded-[86px] p-[9px] fixed bottom-0 left-1/2 -translate-x-1/2 shadow-black-lose bg-dark-gray z-[100]">
        <div className="flex items-center gap-5">
          <a
            href="#hero"
            className="flex group relative justify-center items-center h-[65px] bg-white w-[65px] rounded-full"
          >
            <img
              loading="lazy"
              src={pageLogo}
              alt="navlogo"
              className="h-[43px] group-hover:scale-0 duration-300 w-[43px] rounded-full"
            />
            <span className="group-hover:scale-100 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 duration-300">
              <Icons icon="backToTop" />
            </span>
          </a>
          <div className="flex gap-1">
            {NAV_LINKS_LIST.map((obj, i) => {
              return (
                <a
                  key={i}
                  href={`#${obj.url}`}
                  // onClick={() => handelClick(obj.url)}
                  className={`text-white rounded-md hover:rounded-[60px] font-medium text-base duration-300 ease-in-out transition-all ${activeTabsHandler(
                    obj.url
                  )}  p-4`}
                >
                  {obj.title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
