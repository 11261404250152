import aiPower from "../assets/images/svg/ai-power.svg";
import flexibleIcon from "../assets/images/svg/flexible-icon.svg";
import serviceImg from "../assets/images/svg/get-support-service.svg";
import settingGrowthImg from "../assets/images/svg/get-support-setting-growth.svg";
import settingImg from "../assets/images/svg/get-support-setting.svg";
import globalIcon from "../assets/images/svg/global-icon.svg";
import line from "../assets/images/svg/line-animation.svg";
import restMan from "../assets/images/svg/rest-man-icon.svg";
import secureMoney from "../assets/images/svg/secure-money.svg";
import wordpressLogo from "../assets/images/svg/wordpress-logo.svg";
import afar from "../assets/images/webp/afar.webp";
import canadianParalympi from '../assets/images/webp/canadian-paralympi.webp';
import cannSdi from '../assets/images/webp/cann-sdi.webp';
import cansel from "../assets/images/webp/cansel.webp";
import cpc from "../assets/images/webp/cpc.webp";
import doctorDecisions from "../assets/images/webp/doctor-decisions.webp";
import drDecisions from '../assets/images/webp/dr-decision.webp';
import earls from "../assets/images/webp/earls.webp";
import efw from "../assets/images/webp/efw.webp";
import epson from '../assets/images/webp/epson.webp';
import erasCa from '../assets/images/webp/eras-ca.webp';
import highPerformance from '../assets/images/webp/high-performance.webp';
import integratedWealt from "../assets/images/webp/integrated-wealth.webp";
import isip from '../assets/images/webp/isip.webp';
import learnMate from '../assets/images/webp/learning-mate.webp';
import learningMate from "../assets/images/webp/learning-mates.webp";
import mikeMonkey from "../assets/images/webp/mike-monkey.webp";
import money_talks from "../assets/images/webp/money-talks.webp";
import skillStat from "../assets/images/webp/skill-stat.webp";
import skunkWorks from '../assets/images/webp/skunk-works.webp';
import tradingDesk from "../assets/images/webp/trading-desk.webp";
import {
    BENEFITS_LINK,
    FAQ_LINK,
    HOW_IT_WORKS_LINK,
    PRICING_LINK,
    SERVICES_LINK,
} from "./constants";

export const NAV_LINKS_LIST = [
    {
        title: "How it works",
        url: HOW_IT_WORKS_LINK,
    },
    {
        title: "Benefits",
        url: BENEFITS_LINK,
    },
    {
        title: "Services",
        url: SERVICES_LINK,
    },
    {
        title: "Pricing",
        url: PRICING_LINK,
    },
    {
        title: "FAQs",
        url: FAQ_LINK,
    },
];
export const PARTNER_LOGOS_LIST = [
    afar,
    cansel,
    cpc,
    doctorDecisions,
    earls,
    efw,
    learningMate,
    tradingDesk,
];
export const PROJECT_LIST = [
    {
        image: skillStat,
        name: "Skillstat Inc",
    },
    {
        image: mikeMonkey,
        name: "Mike’s MoneyTalks",
    },
    {
        image: cannSdi,
        name: "Community Airport Newcomers Network",
    },
    {
        image: integratedWealt,
        name: "Integrated Wealth Management",
    },
    {
        image: isip,
        name: "Immigrant Settlement & Integration Program ",
    },
    {
        image: skunkWorks,
        name: "Skunkworks.ca",
    },
    {
        image: erasCa,
        name: "Earls.ca",
    },
    {
        image: drDecisions,
        name: "Dr.Decisions",
    },
    {
        image: learnMate,
        name: "Learningmate",
    },
    {
        image: canadianParalympi,
        name: "Canadian Paralympic Committee",
    },
    {
        image: epson,
        name: "Epson",
    },
    {
        image: highPerformance,
        name: "High Performance Communication Inc",
    },
];
export const FAQ_LIST = [
    {
        title: "What is website maintenance anyway?",
        description: [
            "Website maintenance involves the ongoing process of updating, managing, and monitoring a website to ensure it remains functional, secure, and relevant.It includes regular software updates, such as applying patches to content management systems, plugins, and themes to protect against vulnerabilities.Maintenance also covers content updates to keep information current, engaging, and reflective of the brand’s latest offerings.",
            "Security is a critical component, including tasks like renewing SSL certificates, performing regular backups, and monitoring for any potential threats. Maintenance also involves performance optimization, ensuring the site loads quickly, runs smoothly, and remains accessible across different devices and browsers.",
            "Additionally, website maintenance includes SEO efforts, such as updating meta tags and optimizing content to help the site rank better in search engines. Routine checks for broken links, errors, and user experience issues are also part of the process.",
            "Overall, website maintenance is essential for keeping a site secure, user- friendly, and aligned with the business’s goals.It’s a proactive approach to prevent issues, improve performance, and ensure the website continues to serve its intended purpose effectively over time.",
        ],
    },
    {
        title: "Why is website maintenance important?",
        description: [
            "Website maintenance is crucial for ensuring a secure, functional, and effective online presence. Regular maintenance protects against cyber threats by keeping software and security patches up to date, safeguarding data integrity. It also optimizes website performance, ensuring fast loading times and smooth operation, which enhances user experience and supports higher search engine rankings.",
            "Maintaining a website helps build trust and credibility by presenting a professional, error-free site that consistently offers fresh content. This not only engages visitors but also boosts customer retention. Additionally, routine upkeep can prevent costly issues, extending the website's lifespan and ensuring it adapts to evolving business needs.",
            "From a compliance standpoint, maintenance ensures that the website meets industry regulations regarding security, privacy, and accessibility, avoiding legal risks. Moreover, a well-maintained website better supports digital marketing efforts, leading to improved visibility and customer engagement.",
            "In essence, website maintenance is vital for protecting your investment, supporting business continuity, and ensuring that your website remains a valuable asset that aligns with your business goals.",
        ],
    },
    {
        title: "Will you work on my site, even if it was created by someone else?",
        description: [
            "Yes, and you can rely on us to have developers dedicated to website maintenance who are comfortable working on a wide range of platforms.",
        ],
    },
    {
        title:
            "How long does it take to complete website maintenance changes on my website?",
        description: [
            "The time required to complete website maintenance changes can vary depending on the complexity of the tasks. Routine updates, such as content edits or software updates, might take just a few minutes to an hour. More involved tasks, like fixing security vulnerabilities, optimizing performance, or troubleshooting errors, could take several hours to a day. Larger changes, such as implementing new features or redesigns, might span several days or even weeks. Regular maintenance schedules help ensure that tasks are completed efficiently, minimizing downtime and keeping the website running smoothly.",
        ],
    },
    {
        title: "Can I do website maintenance changes myself?",
        description: [
            "This will depend on the type of website you have. If your website doesn't enable you to do it by yourself, we can suggest you options about converting your website into a CMS (Content Management System).",
        ],
    },
    {
        title:
            "There are so many website maintenance agencies! How do I choose the right one?",
        description: [
            "To choose the right website maintenance agency, focus on their experience, expertise, and range of services. Look for an agency with a proven track record and one that offers comprehensive maintenance, including security, performance optimization, and content management. Ensure they provide tailored packages, responsive support, and clear communication. Check their reputation through reviews and testimonials. While cost is important, prioritize value and the quality of service over the lowest price. By considering these factors, you can select an agency that best aligns with your website's needs and business goals.",
        ],
    },
    {
        title: "Is my website 'down' while you work on it?",
        description: [
            "Your website typically remains live while maintenance work is performed. Most updates and changes are done in the background to avoid disrupting your site's availability. In cases where downtime is necessary, it’s usually scheduled during low-traffic times and kept as brief as possible to minimize any impact.",
        ],
    },
    {
        title: "Is there a contract and how is payment handled?",
        description: [
            "Yes, we operate under a contract that outlines the scope of work, frequency of maintenance, and terms of service. This contract ensures both parties are clear on expectations and responsibilities. Payment is typically handled on a monthly basis, depending on the package you choose. Cancel anytime.",
        ],
    },
    {
        title: "Can you guys redesign my website for me?",
        description: [
            "Yes. Our team will create a complete redesign of your website based on the hottest trends of today combined with tried and tested templates to create something new, sleek and tailored to your company.",
        ],
    },
    {
        title:
            "Can I see the changes before you go live with them to make sure I approve?",
        description: [
            "Absolutely. We can provide you with a link to view and approve your changes, before we go live with the updates.",
        ],
    },
    {
        title: "How do I know I really need website maintenance?",
        description: [
            "You likely need website maintenance if your site faces security risks, slow performance, or frequent errors. If your content is outdated or needs regular updates, maintenance ensures it stays current. Maintaining good search engine rankings also requires ongoing optimization, which is part of regular maintenance. Additionally, if you encounter technical issues like broken links or outdated plugins, or if user experience is a priority, maintenance is essential. Finally, as your business grows, your website must evolve, and maintenance helps it adapt to new needs. If any of these apply, website maintenance is necessary to keep your site effective.",
        ],
    },
    {
        title: "How can I keep the website updated all round the year?",
        description: [
            "You can hand us the website over & just relax. Register with us for a monthly or annual maintenance contract and you'd make sure we'd take care of all your website maintenance requirements throughout the year.",
        ],
    },
    {
        title: "Why does my website need regular site maintenance?",
        description: [
            "Regular site maintenance is essential to keep your website secure, functional, and up-to-date. It protects against security threats by applying the latest updates and patches, ensuring your site is safe from hackers. Maintenance also optimizes performance, keeping your site fast and reliable, which improves user experience and search engine rankings. Regular updates to content and software help your website stay relevant and error-free. Additionally, maintenance ensures compatibility with new devices, browsers, and technologies, allowing your website to adapt as the digital landscape evolves. Without regular maintenance, your site risks becoming outdated, vulnerable, and less effective in achieving your goals.",
        ],
    },
    {
        title:
            "Will unused number of hours in a month be carried to the next month?",
        description: [
            "No. Unused hours cannot be rolled over into the next month. The retainer model means that you have a specific amount of hours each and every month that are allocated to your project. A package or retainer is not the same thing as buying hours. You receive a discounted hourly fee when you enter into a retainer or package model and you received prioritized support.",
        ],
    },
    {
        title: "Do you offer any website maintenance warranty?",
        description: [
            "At Floating World, we offer a website maintenance warranty as part of our service to ensure your website remains secure, functional, and up-to-date. Our warranty typically covers the work we perform, guaranteeing that any issues related to our maintenance efforts will be promptly addressed at no additional cost within the warranty period. This includes fixing bugs, addressing security vulnerabilities, and ensuring that all updates and changes are implemented correctly. The specifics of the warranty, including duration and coverage, can be customized based on your maintenance package. We aim to provide peace of mind, knowing your website is in good hands.",
        ],
    },
];
export const WEB_SUPPORTED_LIST = [
    {
        image: settingImg,
        description: "Subscribe for monthly proactive web maintenance.",
    },
    {
        image: settingGrowthImg,
        description:
            "Receive web & e-commerce optimization, traffic scaling, security & server cost management.",
    },
    {
        image: serviceImg,
        description: "We will tailor our services to maximize value and quality.",
    },
];
export const STRESS_FREE_DATA_LIST = [
    {
        title: "Stress-free web support",
        description:
            "We take responsibility for the monitoring and maintenance of your website so you can focus on what you do best: Serving your customers.",
    },
    {
        title: "Safe & Secure",
        description:
            "We implement regular backups, security protocols & patches, and continuous monitoring to safeguard your data​​ and to keep you and your customers safe.",
    },
    {
        title: "Technical & Strategic Guidance",
        description:
            "We can offer strategic advice based on decades of experience to steer your web business in the right direction.",
    },
];
export const MEMBERSHIP_BASIC_DATA_LIST = [
    "Managing 3 content uploads",
    "Maintaining smooth third-party integration",
    "Maintaining & updating website plugins",
    "Updating PHP, server, and software",
    "Keeping software up to date",
    "Hosting and server management",
    "Server Security",
    "Uptime monitoring ",
    "Backups",
    "Subject to details of intro call",

];
export const MEMBERSHIP_ADVANCED_DATA_LIST = [
    "Multiple content uploads",
    "Maintaining smooth third-party integration",
    "Maintaining & updating website plugins",
    "Updating PHP, server, and software",
    "Keeping software up to date",
    "Hosting and server management",
    "Server Security",
    "Uptime monitoring",
    "Backups",
    "Ecommerce optimization",
    "Speed optimization",
    "Traffic scaling",
    "Custom coding",
    "Price subject to details of intro call",
];
export const AICODING_ALLBUTTON_DATA_LIST = [
    "Mobile Apps",
    "AI",
    "Plugin Coding",
    "Web Coding",
    "Server-side",
    "Security",
    "Backups",
    "Management",
    "Wordpress",
    "Laravel",
    "Integrations",
    "Speed Optimization",

];

// ======== MEMBERSHIP BENEFITS ============
export const MEMBERSHIP_BENEFITS = [
    {
        image: restMan,
        title: "Set it and forget it",
        description:
            "We pay fanatical attention to your website so you don't have to.",
    },
    {
        image: globalIcon,
        title: "Full coverage",
        description:
            "We take care of it all: website & server updates, security, traffic spikes, & e-commerce optimization.",
    },
    {
        image: secureMoney,
        title: "Fixed monthly rate",
        description:
            "No surprises here! We enable you take control of your annual web budget with a fixed monthly retainer.",
    },
    {
        image: flexibleIcon,
        title: "Flexible and Scalable",
        description:
            "We ensure the smooth growth of your subscription based business by making sure you are using the best framework for your needs.",
    },
    {
        image: aiPower,
        title: "Harness the Power of AI",
        description:
            "Stay ahead of the competition by integrating AI into your business operations.",
    },
    {
        image: wordpressLogo,
        title: "Wordpress experts",
        description:
            "Wordpress Experts: We have decades of experience coding with Wordpress. ",
    },
];

// ========= THEY SAID IT BEST ========
export const THEY_SAID_IT_BEST_LIST = [
    {
        description:
            "Aaron and the Floating Web World team did an excellent job building our new website on WordPress. In stark contrast to other website developers, they quickly understood the essence of our company's uniqueness and how to express that through our site. FWW's team are great communicators - succinct, clear and straight-forward without unnecessary jargon that 'techno-phobes' like me really dislike. They delivered excellent work on schedule, on budget, with zero friction. They are a pleasure to work with. Contact me if you want the full story - it's all good!",
        author: "Andrew Ruhland",
        designation: "Owner",
        urlText: "Integrated Wealth Management",
        url: "http://integratedwealthmanagement.ca/",
    },
    {
        description:
            "I find that whenever I run into a technical problem, Aaron is never more than 5 minutes away by telephone and the solution rarely more than 10 minutes beyond. As Editor of Michael Campbell's MoneyTalks.net for the last 5 years, larger projects that FloatingWorld Development take on for us have always been delivered on time, if not early as is often the case. The bottom line is that Aaron and FloatingWorld Development are a First Class Operation. Candidly,  I would simply never consider switching my website development business anywhere else. ",
        author: "Robert Zurrer",
        logo: money_talks,
    },
    {
        description:
            "We have worked with the team at Floating World Web Development on a variety of website projects over the years. We have always found them to be prompt in responding to requests, detailed in their assessment of the work required and on budget. They are also knowledgeable in providing alternate solutions to meeting client functionality requirements and very good about reporting on work in progress (important to us for client reporting). In short, they will continue to be on our preferred list of outsource partners.",
        author: "Skunkworks Marni",
        designation: "Client Services Director",
        urlText: "SkunkWorks.ca",
        url: "http://www.skunkworks.ca/",
        details: ["Marni MacLeod, M.A., LL.B.", "Skunkworks Creative Group Inc."],
    },
    {
        description:
            "Aaron and his team created a cost effective website that is getting us great results on Google. Now we are generating significant income from our website",
        author: "Kaleb Miller",
        designation: "Owner",
        details: ["Superior Electroplating"],
    },
    {
        description:
            "Aaron and Floating World have become a key partner in the development and expansion of the Michael Campbell's MoneyTalks www.moneytalks.net website and online business. He has been able to contribute significantly to the strategic vision and follow through with detailed execution, time-sensitive solutions and pro-active maintenance. ",
        author: "Grant Longhurst",
        designation: "Owner",
        urlText: "HighPCI.com",
        url: "http://www.highpci.com/contact.php",
        details: ["High Performance Communications Inc."],
    },
    {
        description:
            "Floating World Web Development supported us with our original website design as well as online marketing strategy consulting. Was very happy with the results, personalized service, and met deadlines within budget. Feel free to contact me directly of you have any questions.",
        author: "Curtis Mackenzie",
        designation: "Managing Director",
        urlText: "VisionConsulting.jp",
        url: "http://www.visionconsulting.jp/",
        details: ["Vision Consulting Services K.K. - Staffing Solutions Tokyo"],
    },
    {
        description:
            "We have been working with Floating World since 2009. They built our initial website, and then upgraded our website for us in 2013. We have been very impressed by their work-especially their quick response time and their ability to carry out complex tasks. We run a charity site and do not have a huge budget, so we appreciate the effort Floating World takes to minimize maintenance costs for us.  We would not hesitate to recommend their services.",
        author: "Jill Bodie",
        designation: "Managing Director",
        details: ["Sanctuary For Kids"]
    },
    {
        description:
            "The team at Floating World have been providing all our web maintenance needs for our website Moneytalks.net since 2009. We are continually impressed by their round-the-clock availability and excellent customer service. They are consistently able to provide new functionality for our website that allows us to grow our business and take advantage of new opportunities provided by changing technology.",
        author: "Nina Parente",
        urlText: "HighPCI.com",
        url: "http://www.highpci.com/contact.php",
        details: ["High Performance Communications Inc. "]
    },
    {
        description:
            "We worked with Aaron and Floating World to develop our new VoicesToronto.com website. He is very clear and patient and explained joomlas parameters and possibilities throughout development, and helped us create exactly what we had envisioned. We were very happy with their work-especially their online training and ongoing support",
        author: "Jamie Hill",
        designation: "CEO",
        details: ["Voices Radio"]

    },
    {
        description:
            "Floating World has provided us with web development, maintenance, and e-commerce support since 2008. We appreciate their expertise, diligence, timely service, and communication skills.",
        author: "Jason Mclean",
        designation: "Director, People Systems and Technology",
        urlText: "Earls.ca",
        url: "http://www.earls.ca",
        details: ["Earls Restaurant Inc."],
    },
    {
        description:
            "We really appreciated all the hard work Aaron and the Floating World  team put in. They were always ready to help and readily accessible if any issues did popped up! We worked with them for 4 years and were always impressed with their dedication and commitment to delivering solutions even in difficult situations. They helped us take the Canadian Paralympic Committee to the next level with a dynamic website that improved on our older, harder to manage, website. We recommend them to anyone with a Joomla website.",
        author: "Kalie Sinclair",
        designation: "Coordinator, Digital and Social Media",
        urlText: "Paralympic.ca",
        url: "http://www.Paralympic.ca",
        details: ["Canadian Paralympic Committee"],
    },
    {
        description:
            "We are very happy that we chose Floating World to build our new online subscription website for The Sixteenth Century Journal. We had a complex set of needs including an online subscription and product download service as well as special access levels for our customers, but Floating World has delivered everything we need. We recommend them to anyone with a complex web project.",
        author: "Kathryn Brammall, PhD",
        designation: "Professor of History and Managing Editor, Sixteenth Century Journal",
        urlText: "ESCJ.org",
        url: "http://www.ESCJ.org",
    },
    {
        description:
            "Floating worlds has solved all of my web development needs. They always deliver requests professionally, promptly, and on budget. Floating World has allowed me concentrate on other things than worrying about the status of my site. I highly recommend!",
        author: "Ron Prasad",
        designation: "Sales & Marketing Manager",
        urlText: "Novex.ca",
        url: "http://novex.ca",
    },
    {
        description:
            "The 2 things that stand out to me and were essential when the project kicked off and throughout the website process is that Floating World is a very personable and dedicated company and I think it is 100% critical that that’s  reflected.  All your competitors provided almost the exact same info you did for the scope, the thing that set you guys apart was that you asked questions to understand our processes.  The official ‘well let me get back to you in 3 days with a quote for you’, and then the formal follow up call from a sales guy to discuss something that was so vague and promise a price based on a scope of work that wasn’t questioned is ridiculous.  You didn’t do that. There wasn’t any fluff.  You knew the situation was desperate when you came on board and you were working weekends and evenings to try and make things happen so we could launch.  That’s not normal for a web development company.  The service is exceptionally personable and you are very dedicated, that is what sets you apart, that’s your competitive advantage.",
        author: "Nicki Brett",
        designation: "Director of Marketing",
        details: ["Cansel"],
    },
    {
        description:
            "We have beeen working with FloatingWorld for over 5 years. The company has developed our 3 sites: Kostuchmedia.com, Foodserviceandhospitality.com and Hotelierworld.com on the Joomla platform. The company has also been responsible for maintaining the sites as per requested changes and providing tips and suggestions for next steps of development or upgrades, which has been instrumental in keeping our sites fresh and free of viruses. Aaron, the owner of the company is always quick to answer our questions and projects/requests are completed in a timely fashion. Working with Aaron and his team helped us further grow and strengthen our online presence.",
        author: "Maya Tchernina",
        designation: "Web Communications Specialist",
        urlText: "Kostuchmedia.com",
        url: "http://www.kostuchmedia.com",
    },
    {
        description:
            "Aaron and the Floating World team built me an effective website that has allowed me to grow my business. I highly recommend them.",
        author: "Dwane Phillips",
        designation: "Owner",
        details: ["Bossy Divas"],

    },
    {
        description:
            "Floating World has been our web support provider since 2009. They have built two websites for us which we have been very happy with. We appreciate the fact that they are very fast to get back to us whenever we contact them.",
        author: "Mike Morin",
        designation: "Union Representitive",
        urlText: "Cupe403.ca",
        url: "http://www.cupe403.ca",
        details: ["Cupe 403"],
    },
    {
        description:
            "Aaron and his team saved us regarding a multiple security issues on our Joomla site. Without hesitation I recommend them for their experience, prompt action and results.",
        author: "Saul Brown",
        designation: "Owner",
        urlText: "itsaulgood.com",
        url: "https://www.itsaulgood.com/",

    },
    {
        description:
            "Floating World has been the go-to website technology consultants for Afar Communications Inc, since 2007, when we did a major upgrade and moved from a purely static website to a Joomla content management system. We have always been pleased with their high degree of technical competence as well as their high degree of responsiveness when we need urgent assistance - for example due to reconfiguration of the hosting provider's environment or new browser editions suddenly producing warning messages for pages that used to be perfectly acceptable. Throughout it all, we have found the interactions to be pleasant and the cost very competitive.",
        author: "Lars Poulsen",
        designation: "Vice President",
        urlText: "Afar.net",
        url: "http://afar.net",
        details: ["Afar Communications Inc"],
    },
    {
        description:
            "We have  worked with Aaron and the team at Floating World since 2008. One of their top skills is the ability to react quickly and provide solutions when they are most needed.",
        author: "Shawn Johnson",
        designation: "Owner",
        urlText: "Fireboxtraining.com",
        url: "http://www.fireboxtraining.com",
        details: ["Fire Box Training Inc."],
    },
    {
        description:
            "Aaron and his team are excellent in Joomla with a fast turn-around – in short, great service at an affordable price – awesome!",
        author: "Colin McKean",
        designation: "Executive Director",
        urlText: "CanadianBatteryAssociation.ca",
        url: "http://canadianbatteryassociation.ca/",
    },
    {
        description:
            "Aaron and the Floating World team have provided us with reliable and trustworthy development work on various Joomla projects throughout the past few years. They're quick and reasonable and very communicative - We'd have no problem using their services again.",
        author: "Chris Botting",
        designation: "Owner",
        urlText: "Chrisbotting.com",
        url: "http://chrisbotting.com",
    },
    {
        description:
            "My website was set up on Joomla, which as a novice at computers meant nothing to me when the person that set up my webpage recommended it.  All I wanted was the ability to add photo's and to make blogs and minor changes.  The other thing that was discussed at the time was that the suggested web host (Floating World) was a 'green' site and I agreed that this was important to me. So with that in mind, accolades fail me when I talk about my experience with Floating World!  As you may have gathered by now, I was far more interested in making sculpture than learning how computers and web sites work.  I had, what I considered the normal ongoing issues associated with having a presence on-line...security, security, security!!!  Any time that I felt that something wasn't working right, I asked Floating World for help and received it in a timely and professional manner. I would certainly recommend them to anybody that asks...",
        author: "Doug Butler",
        designation: "Sculptor",

    },
    {
        description:
            "After an extensive cross country search for a web development company, we hired Floating World to develop our websites and assist us in creating a digital presence. We had a tall order with our first web, requiring many modifications to existing Joomla templates and modules to fit the needs of our business. Aaron and his team exceeded our expectations, demonstrating: dedication, professionalism, flexibility and tremendous patience, as our internal team learned the art of content management. In essence the team at Floating World became an extension of our digital team, engaging them on several additional projects and general site performance issues. Floating World offers a level of customer service, knowledge and “can do” attitude, that is commendable and I would not hesitate to engage their services for future development and projects.",
        author: "Paula Jordan",
        urlText: "Paula Jordan",
        url: "http://www.linkedin.com/profile/view?id=46850784",
    },
    {
        description:
            "Aaron was extremely helpful with a time constrained project that I hired him for. His depth of knowledge and quick turn around time were much appreciated. I would highly recommend his services.",
        author: "Chris Bartle",
        designation: "President of The Evergreen Group",
        urlText: "Chris Bartle",
        url: "http://www.linkedin.com/in/chrisbartle1",
    },
    {
        description:
            "I am writing this email to strongly recommend the services of Floating World Web Development. I had very specific requirements for my product platform buildout, including the need for an online web application. I started with at square one with FWWD and they helped me make my product a reality. Floating World worked with me to understand my requirements and then delivered a custom web application that worked exactly according to my specifications. I would feel very comfortable using FWWD for future website buildouts.",
        author: "Dr. Brian Fengler",
        designation: "Founder",
        details: ["Doctor Decisions"],

    },
    {
        description:
            "Aaron Handford at Floating World Web Development is simply the best in the work he does. His web designs demonstrate leading edge Joomla skills and are accomplished in amazingly fast turn around times. Futhermore, in this process, he is deeply sensitive to a client's specific needs. Nobody does the work of website development better. Bruce Wm Sargent, Author of FOR LOVE OF YURTS.",
        author: "Bruce Sargent",
        designation: "Author",
        urlText: "Bruce Sargent",
        url: "http://www.linkedin.com/profile/view?id=57966639",
        details: ["For the Love of Yurts"]
    },
    {
        description:
            "We would like to convey the incredible positive experience in dealing with Floating Web Development, Aaron Handford and his team. The world of online developers is a nightmare, after many failed attempts with other companies and endless lost promises of completions we found Floating Web and Aaron. Our needs were diverse, and in many cases, outdated.  We work in a Joomla format, and Aaron was able to deal with our needs, get us into an updated format, develop custom written solutions and deal with my last minute continuous requests of changes all while staying calm, professional, organized and more than anything, delivering it all in an amazing short timeframe. I would highly recommend the services of Floating Web Development to anyone requiring online development.  They do what they say they will do and its done when they say it will be done, period.",
        author: "Peter Martin",
        designation: "Owner",
        details: ["FDI / PBL"]
    },
    {
        description:
            "We contacted Floating World for a small but very time sensitive task. Needless to say, they came through for us in a pinch and I would not hesitate to use their services again",
        author: "Erik Lindquist",
        urlText: "TITUS Canada",
        url: "https://www.titusfurniture.ca/",
    },
    {
        description:
            "I can't say enough good things about your help with the website. Your were very efficient and very helpful all the way to completion which was on schedule. You came up with very creative suggestions for the website's look to choose from but ultimately you always listened to what we were needing. You have a very clear way to explain how something works and how we can use the site. We weare very happy with the website and how easy it is to use and update it ourselves. Anytime we needed support, you provided it quickly and efficiently.",
        author: "Regi Boehringer",
    },
    {
        description:
            "I recently had a chance to work with Aaron Handford with Floating World while providing project management support on a web site build for a client.  Aaron was excellent to work with. He brought good ideas to the project, was personable and very flexible as client timelines shifted a lot during the project. He managed the cutover to the new site well while coordinating with the SEO vendor to ensure that site’s excellent search rankings didn’t suffer. The end product was great value for my clients’ investment in the site.",
        author: "Sandor",
        urlText: "Sandor Kiss ",
        url: "www.linkedin.com/in/sandorkiss",
    },
    {
        description:
            "I have had the pleasure of working with Aaron Handford and his team for several years now. They were originally contracted at my previous place of work at which they never seemed to disappoint in their professionalism and sense of urgency when getting time sensitive tasks completed. I was completely new to Joomla when I was introduced to Aaron...he took the time to make sure I knew how to use Joomla to complete all small tasks independently. I would recommend Floating World to any and all of my contacts.",
        author: "Manpreet Deol",
        urlText: "Manpreet Deol",
        url: "http://www.linkedin.com/in/manpreetdeol",
    },
    {
        description:
            "We have been working with Floating World since 2010. They helped us make changes to our original website, and then help migrate our website to the Shopify e-commerce system. We have appreciated Aaron and team's promptness and professionalism, and we highly recommend them.",
        author: "Mike McNerney",
        designation: "Owner",
        urlText: "GreenBullProducts.com",
        url: "http://www.greenbullproducts.com",
        details: ["Green Bull Products Inc."]
    },
    {
        description:
            "I came to Floating World for help setting up my website. I needed experts, as the Web is not my sphere of expertise. I am glad I found them! Aaron and his team were able to quickly deal with my issues, they were patient with my questions - and the price was very reasonable!",
        author: "Jan Gale",
        urlText: "Sulishealing",
        url: "http://www.sulishealing.com",
    },
];
export const THREE_LINE_LIST = [line, line, line]