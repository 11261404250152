import React from "react";
import { Link } from "react-router-dom";
import Paragraph from "./Paragraph";
const ProjectCard = ({ data, aos }) => {
  return (
    <div data-aos={`${aos && "fade-up"}`} className="relative mt-6 lg:mt-8">
      <Link
        to="#"
        className="rounded-2xl mx-auto xl:rounded-3xl shadow-black-project group overflow-hidden max-w-[476px] max-custom-sm:min-w-[285px] min-w-[320px] sm:min-w-[270px] md:min-w-[212px] object-cover flex flex-col xl:min-w-[309px] 2xl:min-w-[400px] w-full relative z-10 max-custom-sm:h-[285px] h-[320px] sm:h-[288px] md:h-[224px] xl:h-[309px] 2xl:h-[400px]"
      >
        <img
          loading="lazy"
          src={data.image}
          alt="Project list"
          className="w-full group-hover:scale-105 object-cover object-left duration-300 grow  h-full"
        />
        <div className="bg-black group-hover:bg-opacity-100 duration-300 bg-opacity-50 absolute bottom-0 left-0 w-full py-4 px-4 xl:py-6 xl:px-6 2xl:px-[35px]">
          <Paragraph
            small
            white
            left
            bold
            className="line-clamp-1 overflow-ellipsis"
          >
            {data.name}
          </Paragraph>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;
