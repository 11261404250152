import { gsap } from "gsap";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import triangle from "../assets/images/svg/triangle-img.svg";
import grandPeople from "../assets/images/webp/grand-people.webp";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { STRESS_FREE_LINK } from "../utils/constants";
import { STRESS_FREE_DATA_LIST, THREE_LINE_LIST } from "../utils/helper";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Paragraph from "./common/Paragraph";

const StressFree = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: STRESS_FREE_LINK,
    setHandleActiveTag,
  });
  useEffect(() => {
    const mm = gsap.matchMedia();
    // Creates a GSAP timeline for animations on larger screens
    mm.add("(min-width:768px)", () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".line-animation",
          start: "top center",
          end: "top center",
          scrub: false,
        },
      });
      // Animation: Expands the first line from 0 to 149px in width and scales it from 0 to 1
      tl.fromTo(
        ".first-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
      // Animation: Expands the second line similarly
      tl.fromTo(
        ".second-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
      // Animation: Expands the third line similarly
      tl.fromTo(
        ".third-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
    });
    // Creates a GSAP timeline for animations on smaller screens
    mm.add("(max-width:767.98px)", () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".line-animation-mobile",
          start: "top center",
          end: "top center",
          scrub: false,
        },
      });
      // Animation: Expands the first line from 0 to 149px in width and scales it from 0 to 1 (on smaller screens)
      tl.fromTo(
        ".first-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
      // Animation: Expands the second line similarly (on smaller screens)
      tl.fromTo(
        ".second-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
      // Animation: Expands the third line similarly (on smaller screens)
      tl.fromTo(
        ".third-line",
        {
          maxWidth: 0,
          scale: 0,
        },
        {
          maxWidth: "149px",
          scale: 1,
        }
      );
    });
  }, []);

  return (
    <div
      id="stressfree"
      className="px-4 sm:px-7 md:px-9 lg:px-20  mx-auto max-w-[1238px] bg-white py-12 md:py-16 lg:py-20 xl:pt-[121px] xl:pb-[115px] line-animation"
    >
      <div className="flex flex-col lg:items-center lg:flex-row lg:justify-between gap-14 lg:gap-16">
        <div
          data-aos="fade-up"
          className="bg-white line-animation-mobile rounded-xl md:rounded-2xl lg:rounded-3xl sm:shadow-black-project shadow-black-project-sm lg:max-w-[602px] w-full py-6 px-4 md:py-8 md:pr-7 md:ps-8 lg:ps-7 xl:pt-[67px] xl:pb-14 xl:pr-[34px] xl:pl-[58px]"
        >
          <Paragraph big medium left>
            “Floating World has become a key partner in the development and
            expansion of Michael Campbell's MoneyTalks”
          </Paragraph>
          <div className="flex gap-3 sm:gap-5 items-end pt-10 md:pt-12 lg:pt-16 xl:pt-[74px]">
            <Paragraph left bold className="lg:text-nowrap">
              Grant Longhurst
              <Link
                className="block font-medium"
                target="_blank"
                to={"https://highpci.com/"}
              >
                HighPCI.com
              </Link>
            </Paragraph>
            <div className="relative">
              <img
                src={triangle}
                alt="traingle"
                className="absolute pointer-events-none w-16 md:w-[90px] lg:w-24 xl:w-[118px] top-1/2 -translate-y-1/2 -left-7 lg:left-[-70px]"
              />
              <div className="flex flex-col absolute top-[48%] pointer-events-none -translate-y-1/2 -left-1 lg:left-[-35px]">
                {THREE_LINE_LIST.map((obj, i) => (
                  <img
                    key={i}
                    loading="lazy"
                    className={`pointer-events-none w-20 md:w-[90px] lg:w-32 xl:w-[149px] ${
                      i === 0
                        ? "first-line"
                        : i === 1
                        ? "second-line"
                        : i === 2 && "third-line"
                    }`}
                    src={obj}
                    alt="triangle"
                  />
                ))}
              </div>
              <img
                loading="lazy"
                className="size-[175px] lg:size-[200px] xl:size-[285px] xl:me-1"
                src={grandPeople}
                alt="grand"
              />
            </div>
          </div>
        </div>
        <div className="gap-8 lg:gap-10 xl:gap-[53px] flex flex-col w-full lg:max-w-[569px]">
          {STRESS_FREE_DATA_LIST.map((obj, i) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-delay="100"
                key={i}
                className="flex flex-col gap-4 md:gap-[22px]"
              >
                <Paragraph left big bold>
                  {obj.title}
                </Paragraph>
                <Paragraph left small className={"!leading-127"}>
                  {obj.description}
                </Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StressFree;
