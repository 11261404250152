import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import logoAnimation from "../json/logo-animation.json";
import { FOOTER_LINK } from "../utils/constants";
import { NAV_LINKS_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";

const Footer = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: FOOTER_LINK,
    setHandleActiveTag,
  });

  const lottieRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  useScrollHandlers({
    elementId: FOOTER_LINK,
    setHandleActiveTag,
  });

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById("footer");
      const rect = footerElement.getBoundingClientRect();
      // Checks if the footer is in view by comparing its position to the viewport
      const isInView = rect.top <= window.innerHeight && rect.bottom >= 500;
      // Checks if the footer is in view by comparing its position to the viewport
      if (isInView && lottieRef.current && !hasPlayed) {
        lottieRef.current.play();
        setHasPlayed(true); // Sets the flag to true to prevent replaying the animation
      } else if (!isInView && lottieRef.current && !hasPlayed) {
        lottieRef.current.stop();
      }
    };
    // Stops the Lottie animation if the footer is out of view and the animation hasn't been played yet
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasPlayed]);

  return (
    <footer
      id="footer"
      className="pt-24 lg:pt-40 xl:pt-[250px] pb-16 sm:pb-20 md:pb-40 xl:pb-48 2xl:pb-[262px] bg-light-black rounded-b-2xl sm:rounded-b-36 mb-3.5"
    >
      <div className="relative items-center flex gap-2 lg:gap-5 justify-center z-10">
        <div className="lg:w-[200px] -translate-y-5 w-12 sm:w-24 md:w-28 lg:h-[200px] h-12 sm:h-24 md:h-28 overflow-hidden relative">
          <div className="w-[90px] sm:w-[200px] lg:w-[300px] h-[90px] sm:h-[200px] lg:h-[300px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180">
            <Lottie
              className="w-full h-full"
              animationData={logoAnimation}
              loop={false}
              lottieRef={lottieRef}
              autoplay={false}
            />
          </div>
        </div>
        {/* <h2 className="2xl:text-custum-2xl text-nowrap max-custom-sm:text-2xl text-[26px] sm:text-5xl md:text-6xl lg:text-7xl xl:text-custum-xl text-center font-bold mb-10 text-dark-grey !leading-60">
          Floatingworld Inc.™
        </h2> */}
        <h2
          className="text-center font-bold mb-10 text-dark-grey !leading-[60px] text-[clamp(1.5rem,7.8vw,8.4375rem)]"
        >
          Floatingworld Inc.™
        </h2>


      </div>
      <ul className="md:pt-10 pt-6 flex justify-center max-w-[614px] sm:px-0 px-4 mx-auto gap-6 max-md:flex-wrap sm:gap-10 md:gap-16 items-center">
        {NAV_LINKS_LIST.map((obj, i) => (
          <li key={i}>
            <a
              href={`#${obj.url}`}
              className="text-black text-nowrap !leading-127 font-medium text-lg duration-500 ease-in-out transition-all hover:text-blue-700"
            >
              {obj.title}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
