import { useEffect, useState } from "react";
import Icons from "./Icons";

const BackToTop = () => {
  const [scrollValue, setScrollValue] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollValue(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollValue > 200) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  }, [scrollValue]);

  const scrollTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  return (
    <div
      className={`right-4 md:hidden bottom-4 md:right-6 md:bottom-6 2xl:right-[calc((100vw-1440px)/2)] border-0 fixed z-[200] transition-all duration-300 ${showScrollTop
        ? "scale-100 translate-y-0"
        : "scale-0 translate-y-[40px]"
        }`}
    >
      <div
        onClick={scrollTopHandler}
        className="h-9 w-9 sm:h-10 border bg-light-blue sm:w-10 rounded-full overflow-hidden bg-dodgerBlue cursor-pointer bg-black transition-all duration-500 flex justify-center items-center hover:shadow-lg hover:shadow-gray-500"
      >
        <Icons icon="backToTop" />
      </div>
    </div>
  );
};


export default BackToTop
