import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import './App.css';
import AiCoding from './components/AiCoding';
import BackToTop from './components/common/BackToTop';
import NavBar from './components/common/NavBar';
import CommonlyQuestion from './components/CommonlyQuestion';
import Footer from './components/Footer';
import Hero from './components/Hero';
import JoinFolatingWorld from './components/JoinFolatingWorld';
import MembershipBenefits from './components/MembershipBenefits';
import MoneyTalks from './components/MoneyTalks';
import NeverBack from './components/NeverBack';
import PartnersSlide from './components/PartnersSlide';
import Projects from './components/Projects';
import StressFree from './components/StressFree';
import WebSupport from './components/WebSupport';
import { useScrollHandlers } from "./utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./components/common/GlobalProvider";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
    });
  }, []);
  const { setHandleActiveTag } = useGlobalInfoProvider();

  // Adjust the function call based on the actual implementation of UseScrollHandlers
  useScrollHandlers({
    elementId: "",
    setHandleActiveTag,
  });
  return (
    <>
      <NavBar />
      <div className="px-3.5">
        <div className='overflow-clip max-w-[1600px] mx-auto'>
          <BackToTop />
          <Hero />
          <PartnersSlide />
          <Projects />
          <WebSupport />
          <MoneyTalks />
          <NeverBack />
          <StressFree />
          <MembershipBenefits />
          <AiCoding />
          <JoinFolatingWorld />
          <CommonlyQuestion />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
