import React, { useState } from "react";
import { FAQ_LINK } from "../utils/constants";
import { FAQ_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Icons from "./common/Icons";
import SubHeading from "./common/SubHeading";
import ScheduleNow from "./ScheduleNow";

const CommonlyQuestion = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: FAQ_LINK,
    setHandleActiveTag,
  });
  const [expandedQuestion, setExpandedQuestion] = useState(0);
  const toggleAccordion = (index) => {
    setExpandedQuestion(expandedQuestion === index ? -1 : index);
  };
  return (
    <div
      id="faq"
      className="bg-light-black pt-24 md:pt-32 lg:pt-40 xl:pt-[189px]"
    >
      <div className="px-4 sm:px-7 md:px-9 lg:px-20 mx-auto max-w-[1488px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 relative max-2xl:gap-[86px] max-2xl:gap-y-16 gap-[70px]">
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="lg:max-w-[570px] lg:pt-[30px]"
          >
            <SubHeading darkGray left className="xl:!text-5xl lg:!text-4xl">
              Commonly asked questions
            </SubHeading>
            <div className="mt-12">
              {FAQ_LIST.map((value, index) => (
                <div key={index} className="relative z-10 mx-auto w-full">
                  <div className="w-full rounded-md mb-[31px]">
                    <button
                      onClick={() => toggleAccordion(index)}
                      type="button"
                      className="flex w-full items-center justify-between gap-4 text-left text-base font-semibold !leading-127 text-black sm:text-lg"
                      aria-controls={`faqs-text-${index}`}
                    >
                      <p className="flex gap-2">{value.title}</p>
                      <span
                        className={`duration-300 ${
                          expandedQuestion === index && "-rotate-180"
                        }`}
                      >
                        <span
                          className={`${
                            expandedQuestion === index && "rotate-180"
                          } duration-300`}
                        >
                          <Icons icon="accordionArrow" />
                        </span>
                      </span>
                    </button>
                    <div
                      id={`faqs-text-${index}`}
                      role="region"
                      aria-labelledby={`faqs-title-${index}`}
                      className={`grid overflow-hidden duration-300 ${
                        expandedQuestion === index
                          ? "grid-rows-[1fr] opacity-100"
                          : "grid-rows-[0fr] opacity-0"
                      }`}
                    >
                      <div className={`overflow-hidden`}>
                        {value.description.map((description, i) => (
                          <p
                            key={i}
                            className="pt-3 text-sm sm:text-base font-normal !leading-145 opacity-90 sm:pb-0 pe-12"
                          >
                            {description}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ScheduleNow />
        </div>
      </div>
    </div>
  );
};

export default CommonlyQuestion;
