import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import { PROJECT_LINK } from "../utils/constants";
import { PROJECT_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Paragraph from "./common/Paragraph";
import ProjectCard from "./common/ProjectCard";

const Projects = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: PROJECT_LINK,
    setHandleActiveTag,
  });

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const mm = gsap.matchMedia();
    // Creates a GSAP timeline for animations on larger screens
    mm.add("(min-width:640px", () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".parent",
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
        },
      });
      // Animation: Moves the '.card' elements from their current position to 100% left (off-screen)
      tl.fromTo(
        ".card",
        {
          xPercent: 0,
        },
        {
          xPercent: -100,
        }
      );
      // Animation: Rotates the '.circle-rotate' elements from 180 to 360 degrees
      tl.to(".circle-rotate", { rotate: 180 }, { rotate: 360 });
    });
    // Creates a GSAP timeline for animations on smaller screens
    mm.add("(max-width:639.98px", () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".parent",
          start: "top bottom",
          end: "bottom top",
          scrub: 4,
        },
      });
      // Animation: Moves the '.card' elements from their current position to 250% left (more than off-screen)
      tl.fromTo(
        ".card",
        {
          xPercent: 0,
        },
        {
          xPercent: -250,
        }
      );
      // Animation: Rotates the '.circle-rotate' elements from 180 to 360 degrees
      tl.to(".circle-rotate", { rotate: 180 }, { rotate: 360 });
    });
  }, []);

  return (
    <div
      id="project"
      className="relative pt-16 lg:pt-20 xl:pt-[126px] bg-light-black "
    >
      <div className="pt-16 lg:pt-20 xl:pt-[68px]">
        <div className="flex items-center relative z-10 gap-4 md:gap-[31px] parent">
          {PROJECT_LIST.slice(0, 6).map((obj, i) => (
            <div className="card" key={i}>
              <ProjectCard data={obj} />
            </div>
          ))}
        </div>
        <div className="sm:size-[120px] size-28 lg:size-[132px] xl:size-[185px] pointer-events-none flex justify-center items-center rounded-full absolute top-20 md:top-24 lg:top-[127px] left-[35%] md:left-[23%] z-[11]">
          <div className="bg-white shadow-black-lose rounded-full w-full h-full absolute inset-0 z-10 circle-rotate"></div>
          <div className="relative z-50">
            <Paragraph
              big
              medium
              className="!leading-115 !font-poppins -rotate-[15deg]"
            >
              Latest <br /> Clients
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
