import { default as React, useRef } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import plus from "../assets/images/svg/plus-animation.svg";
import { MONEYTALKS_LINK } from "../utils/constants";
import { THEY_SAID_IT_BEST_LIST } from "../utils/helper";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Icons from "./common/Icons";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";
import SubHeading from "./common/SubHeading";

const MoneyTalks = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: MONEYTALKS_LINK,
    setHandleActiveTag,
  });
  const swiperRef = useRef(null);
  return (
    <div
      id="moneytalks"
      className="py-12 md:py-16 lg:py-20 xl:pt-[110px] xl:pb-[101px] bg-white px-4 sm:px-7 md:px-9 lg:px-20 relative"
    >
      <img
        loading="lazy"
        className="absolute -top-[7%] md:-top-[12%] w-20 sm:w-24 md:w-32 lg:w-40 xl:w-[190px] left-[63%] pointer-events-none"
        src={plus}
        alt="plus animation"
      />
      <SubHeading data-aos="fade-up">They said it best</SubHeading>
      <div className="relative  max-w-[830px] mx-auto">
        <Swiper
          ref={swiperRef}
          effect={"fade"}
          slidesPerView={1}
          spaceBetween={30}
          speed={1000}
          loop={true}
          grabCursor={true}
          autoplay={{
            delay: 6500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay, EffectFade]}
          className="mySwiper"
        >
          {THEY_SAID_IT_BEST_LIST.map((obj, i) => {
            return (
              <SwiperSlide className="bg-white !h-auto" key={i}>
                <div className="!h-full">
                  <Paragraph
                    data-aos="fade-up"
                    data-aos-delay="100"
                    big
                    className="my-7 md:my-8 lg:my-10 xl:mt-11 xl:mb-14 max-w-[830px] !leading-145 md:!line-clamp-4 !line-clamp-6 !overflow-ellipsis"
                  >
                    {obj.description}
                  </Paragraph>
                  <div className="flex justify-center items-center sm:gap-4 gap-2 md:gap-6 lg:gap-9">
                    <div>
                      <Paragraph
                        right
                        small
                        bold
                        className="!leading-145 text-nowrap"
                      >
                        {obj.author}
                      </Paragraph>
                      <Paragraph
                        right
                        small
                        bold
                        className="!leading-145 !font-medium"
                      >
                        {obj.designation}
                      </Paragraph>
                    </div>
                    {(obj.details || obj.url || obj.logo) && (
                      <span className="w-0.5 h-16 md:h-[83px] block bg-alto"></span>
                    )}
                    <div>
                      {obj.details &&
                        Array.isArray(obj.details) &&
                        obj.details.map((detail, i) => {
                          return (
                            <Paragraph key={i} left className="max-sm:overflow-ellipsis max-sm:line-clamp-1">
                              {detail}
                            </Paragraph>
                          );
                        })}
                      {obj.logo ? (
                        <Link to="https://mikesmoneytalks.ca/" target="_blank">
                          <img
                            src={obj.logo}
                            alt="money"
                            className="w-[160px] md:w-[250px] lg:w-[350px] min-h-14"
                          />
                        </Link>
                      ) : (
                        <Link
                          to={obj.url}
                          target="_blank"
                          className="hover:text-rosy-pink text-sm md:text-xl text-pacific-blue font-bold duration-300 inline"
                        >
                          {obj.urlText}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="flex justify-center gap-6 lg:mt-0 mt-10">
          <div
            className="group md:hover:bg-black active:bg-black duration-300 flex items-center justify-center lg:absolute sm:size-[60px] size-12 rounded-full bg-light-black shadow-slider-button -translate-y-1/2 top-1/2 -left-20 xl:-left-40 2xl:-left-[210px] cursor-pointer"
            onClick={() => swiperRef.current?.swiper.slidePrev()}
          >
            <Icons
              className="md:group-hover:fill-light-black group-active:fill-light-black duration-300"
              icon="sliderLeftArrow"
            />
          </div>
          <div
            className="group md:hover:bg-black active:bg-black duration-300 flex items-center justify-center lg:absolute sm:size-[60px] size-12 rounded-full bg-light-black shadow-slider-button -translate-y-1/2 top-1/2 -right-20 xl:-right-40 2xl:-right-[210px] cursor-pointer"
            onClick={() => swiperRef.current?.swiper.slideNext()}
          >
            <span className="ms-2">
              <Icons
                className="md:group-hover:fill-light-black group-active:fill-light-black duration-300"
                icon="sliderRightArrow"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div data-aos="fade-up" data-aos-delay="200">
          <PrimaryButton
            target="_blank"
            url="https://www.dropbox.com/scl/fi/af2uf0vht13r5bgklq0fu/Floatingworld-Inc-2024-Testimonials.pdf?rlkey=1vfk82bn41j5yw0zk8v3czyhl&dl=1"
            className="!inline-block lg:mt-10 mt-6"
            download="Floatingworld-Inc-2024-Testimonials.pdf"
          >
            View All
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default MoneyTalks;
