import React from "react";
import { Link } from "react-router-dom";
import Icons from "./common/Icons";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";

const ScheduleNow = () => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay="400"
      className="2xl:max-w-[726px] xl:max-w-[530px] lg:max-w-[380px] ms-auto w-full"
    >
      <div className="bg-white group rounded-3xl lg:!sticky top-5 max-sm:px-3 shadow-black-project text-center flex flex-col items-center py-10 md:py-12 lg:py-16 xl:py-[93px] w-full">
        <Icons icon="smileMessage" />
        <h2 className="xl:text-5xl px-4 text-3xl lg:text-4xl max-w-[450px] text-center mx-auto mt-9 font-bold !leading-120">
          Book a 15-minute intro call
        </h2>
        <PrimaryButton url={"https://calendly.com/aaronhandford/15-minute-intro-info-call"} target="_blank" className="mt-7 text-center justify-center max-w-[250px] w-full">
          Schedule Now
        </PrimaryButton>
        <Paragraph
          bold
          small
          className="text-bright-cerulean mt-6 px-1 xl:mt-[61px] max-xl:!text-sm !leading-200"
        >
          <span className="font-medium text-black">Prefer to email?&nbsp;</span>

          <Link
            className="hover:text-rosy-pink duration-300"
            to="mailto:hello@floatingworld.ca"
          >
            hello@floatingworld.ca
          </Link>
        </Paragraph>
      </div>
    </div>
  );
};

export default ScheduleNow;
