import React from "react";
const SubHeading = ({
  children,
  className,
  left,
  right,
  maxWidth,
  darkGray,
  white,
  ...props
}) => {
  const { ...rest } = props;

  return (
    <h2
      {...rest}
      style={{ maxWidth: `${maxWidth && maxWidth}px` }}
      className={`${className && className} ${
        left
          ? "ml-0 mr-auto text-left"
          : right
          ? "ml-auto mr-0 text-right"
          : "mx-auto text-center"
      } ${
        darkGray ? "text-dark-grey" : white ? "text-white" : "text-black"
      } text-3xl md:text-4xl lg:text-5xl xl:text-custum-lg !leading-107 font-bold`}
    >
      {children}
    </h2>
  );
};

export default SubHeading;
