import React from "react";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { BENEFITS_LINK } from "../utils/constants";
import { MEMBERSHIP_BENEFITS } from "../utils/helper";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import SubHeading from "./common/SubHeading";

const MembershipBenefits = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: BENEFITS_LINK,
    setHandleActiveTag,
  });
  return (
    <div
      id="benefits"
      className="xl:pt-[109px] xl:pb-[145px] lg:py-24 md:py-20 sm:py-16 py-12 bg-light-black rounded-2xl sm:rounded-36"
    >
      <div className="max-w-[1400px] mx-auto px-4 sm:px-7 md:px-9 lg:px-20">
        <SubHeading data-aos="fade-up">Membership Benefits</SubHeading>
        <div className="xl:mt-20 lg:mt-16 md:mt-14 sm:mt-12 mt-8 grid lg:grid-cols-3 sm:grid-cols-2 gap-7 2xl:gap-x-6 2xl:gap-y-20">
          {MEMBERSHIP_BENEFITS.map((obj, i) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-delay="200"
                key={i}
                className="max-w-[340px] flex flex-col  items-center mx-auto"
              >
                <div className="md:mb-6 sm:h-[84px] md:h-28 xl:h-[158px] flex items-center mb-4">
                  <img
                    loading="lazy"
                    className="2xl:w-auto pointer-events-none lg:w-[100px] sm:w-20 w-16"
                    src={obj.image}
                    alt="benifits"
                  />
                </div>
                <h3 className="font-bold text-black !leading-130 lg:text-custum-md md:text-2xl sm:text-xl text-lg text-center">
                  {obj.title}
                </h3>
                <p className="font-normal text-black !leading-145 lg:text-lg sm:text-base text-sm md:mt-4 mt-2 text-center">
                  {obj.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembershipBenefits;
