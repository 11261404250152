// NAVBAR LINKS
export const HOW_IT_WORKS_LINK = "how_it_work";
export const HERO_LINK = "Hero";
export const PROJECT_LINK = "project";
export const PARTNERSHIP_LINK = "partnerslide";
export const MONEYTALKS_LINK = "moneytalks";
export const BENEFITS_LINK = "benefits"
export const NEVER_BACK_LINK = "neverback"
export const STRESS_FREE_LINK = "stressfree"
export const FOOTER_LINK = "footer"
export const SERVICES_LINK = "services"
export const PRICING_LINK = "memberships"
export const FAQ_LINK = "faq";