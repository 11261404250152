
const PrimaryButton = ({
  children,
  left,
  onClick,
  className,
  transparent,
  url,
  ...rest
}) => {
  const buttonClass = transparent ? "!border-white" : "";
  return url ? (
    <a { ...rest }
      onClick={onClick}
      href={url}
      className={`${className} ${buttonClass} ${left ? "ml-0 mr-auto text-left" : "mx-auto text-center flex"
        } text-white border border-black bg-dark-gray px-10 lg:px-[49.5px] rounded-lg md:rounded-[14px] text-base md:text-lg lg:text-xl !leading-125 py-3 md:py-4 xl:py-5 font-bold !duration-300 !ease-linear shadow-black-lose hover:shadow-black-dark hover:!bg-light-gray`}
    >
      {children}
    </a>
  ) : (
    <button
      onClick={onClick}
      className={`${className} ${buttonClass} ${left ? "ml-0 mr-auto text-left" : "mx-auto text-center flex"
        } text-white border border-black bg-dark-gray px-10 lg:px-[49.5px] rounded-lg md:rounded-[14px] text-base md:text-lg lg:text-xl !leading-125 py-3 md:py-4 xl:py-5 font-bold !duration-300 !ease-linear shadow-black-lose hover:shadow-black-dark hover:!bg-light-gray`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
