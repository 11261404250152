import React from "react";
import fiveTriangle from "../assets/images/webp/five-triangle.webp";
import { NEVER_BACK_LINK } from "../utils/constants";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";
import SubHeading from "./common/SubHeading";

const NeverBack = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: NEVER_BACK_LINK,
    setHandleActiveTag,
  });
  return (
    <div
      id="neverback"
      className="py-12 md:py-16 lg:py-20 xl:pt-[120px] xl:pb-[121px] bg-light-black px-4 sm:px-7 md:px-9 lg:px-20 relative rounded-2xl sm:rounded-36"
    >
      <SubHeading data-aos="fade-up" className={"max-w-[880px]"} darkGray>
        We've revolutionized web support
      </SubHeading>
      <Paragraph
        big
        className={
          "py-7 md:py-8 lg:pt-9 lg:pb-11 xl:pb-[66px] max-w-[788px] !leading-145"
        }
      >
        Floatingworld replaces unreliable freelancers and expensive agencies for
        one flat monthly fee, with unwavering support that will blow your mind.
      </Paragraph>
      <div data-aos="fade-up" data-aos-delay="200">
        <PrimaryButton url="#memberships" className=" mx-auto !flex max-w-max">
          See Plans
        </PrimaryButton>
      </div>
      <img
        loading="lazy"
        data-aos="fade-down"
        data-aos-delay="200"
        src={fiveTriangle}
        alt="fiveTriangle"
        className="absolute pointer-events-none w-4 md:w-6 lg:w-8 xl:w-[38px] left-[calc(50%+124px)] xl:left-[calc(56%+124px)] -bottom-7 md:-bottom-12 xl:-bottom-[76px]"
      />
    </div>
  );
};

export default NeverBack;
