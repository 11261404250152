
import { useEffect } from "react";
// import { explore_use_cases_text } from "./Constants";

// Custom hook to manage scroll events
export const useScrollHandlers = ({ elementId, setHandleActiveTag }) => {
  let offset = 40;

  // Function to handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const rect = element.getBoundingClientRect();
        const isSmallScreen = window.innerWidth <= 1024;
        const topLimit = isSmallScreen ? 750 : 300;
        const bottomLimit = isSmallScreen ? 200 : 300;
        const isTop = rect.top - offset <= topLimit && rect.bottom >= bottomLimit;
        if (isTop) {
          setHandleActiveTag(elementId);
        } else if (elementId === "") {
          setHandleActiveTag("");
        }
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementId, offset, setHandleActiveTag]);
};
