import React from "react";
import { Link } from "react-router-dom";
import { PRICING_LINK } from "../utils/constants";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Icons from "./common/Icons";
import Paragraph from "./common/Paragraph";
import SubHeading from "./common/SubHeading";
import PricingMembership from "./PricingMembership";

const JoinFolatingWorld = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: PRICING_LINK,
    setHandleActiveTag,
  });

  return (
    <div id="memberships" className="bg-light-black 2xl:pt-[101px] rounded-t-2xl sm:rounded-t-36">
      <div className="max-w-[1488px] px-4 sm:px-7 md:px-9 lg:px-20  mx-auto pt-12 md:pt-16 lg:pt-20 xl:pt-40">
        <div className="flex flex-col lg:flex-row gap-8 md:gap-5 xl:gap-8 h-full">
          <div
            data-aos="fade-right"
            className="bg-dark-black rounded-xl md:rounded-2xl lg:rounded-3xl lg:sticky top-6 max-h-[890px] 2xl:min-h-[890px] h-full lg:max-w-[476px] w-full shadow-black-project px-4 py-6 md:py-8 lg:p-6 xl:pt-[65px] xl:pb-[59px]"
          >
            <div className="flex max-w-[181px] w-full gap-3 items-center px-4 py-2.5 md:py-[11px] md:ps-4 md:pl-4 bg-rosy-pink rounded-3xl md:rounded-[50px] mb-6 md:mb-[27px]">
              <span className="w-3.5 h-3.5 rounded-full bg-white animate-dot-white block"></span>
              <p className="text-white text-sm md:text-base font-bold">
                Slots available
              </p>
            </div>
            <SubHeading left white className={"!leading-115"}>
              Join <span className="lg:block">Floating </span>
              World
            </SubHeading>
            <div className="mt-12 md:mt-16 lg:mt-20 xl:mt-[91px] 2xl:mt-[160px]">
              <Link target="_blank" to="https://calendly.com/aaronhandford/15-minute-intro-info-call" className="border group hover:bg-opacity-70 duration-300 flex items-center justify-between gap-2 border-black bg-black w-full rounded-lg md:rounded-[13px] p-5 lg:px-[26px] lg:pb-8">
                <div className="max-w-[230px] sm:max-w-[240px] lg:max-w-[274px]">
                  <Paragraph white left small bold className={"pb-[11px]"}>
                    Book a 15 min intro call
                  </Paragraph>
                  <Paragraph white left medium>
                    Learn how Floatingworld works and how it can help you.
                  </Paragraph>
                </div>
                <span className="group-hover:translate-x-2 duration-300 mt-2">
                  <Icons icon="rightArrow" />
                </span>
              </Link>
            </div>
            <div className="mt-5 xl:mt-[21px]">
              <Link
                to="mailto:hello@floatingworld.ca?subject=Referring%20someone%20that%20needs%20support"
                className="border group hover:bg-opacity-70 duration-300 flex items-center justify-between gap-2 border-black bg-black w-full rounded-lg md:rounded-[13px] p-5 lg:px-[26px] lg:pb-8"
              >
                <div className="max-w-[230px] sm:max-w-[240px] lg:max-w-[274px]">
                  <Paragraph white left small bold className={"pb-[11px]"}>
                    Refer a friend & earn
                  </Paragraph>
                  <Paragraph white left medium>
                    Earn 5% monthly recurring commissions for each referral.
                  </Paragraph>
                </div>
                <span className="group-hover:translate-x-2 duration-300 mt-2">
                  <Icons icon="rightArrow" />
                </span>
              </Link>
            </div>
          </div>
          <PricingMembership />
        </div>
      </div>
    </div>
  );
};

export default JoinFolatingWorld;
